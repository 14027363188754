
<template>
  <div>
    <a-tabs default-active-key="1" >
      <!-- @change="callback" -->
       <!-- 资讯发布 -->
      <a-tab-pane key="1" tab="办事指南">
        <guidance></guidance>
      </a-tab-pane>
      <!-- 资讯分类 -->
      <a-tab-pane key="2" tab="事件分类" force-render>
        <eventClassification></eventClassification>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
 
 <script>
 import guidance from "./components/guidance.vue"
 import eventClassification from "./components/eventClassification.vue"
export default {
  components: {guidance,eventClassification},
  data() {
    return {
      current: ["mail"],
    };
  },

  methods: {},

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
</style>